<script setup lang="ts">

import { useUserStore } from '@/stores/user';

defineOptions({
  name: 'MyTeamItem'
});

const { push, replace } = useRouter();
const { t } = useI18n();

const logout = ref(false);

const { loginOut } = useUserStore();

const handleLogOut = async () => {
  try {
    await loginOut();
    replace('/login');
    logout.value = false;
  } catch {
  }
};

</script>

<template>
  <q-item clickable v-ripple @click="logout = true">
    <q-item-section top avatar>
      <q-avatar rounded>
        <q-icon name="app:mine_logOut" size="md" />
      </q-avatar>
    </q-item-section>
    <q-item-section>
      <q-item-label class="text-subtitle1 text-weight-bolder">
        {{ t('mine.logOut') }}
      </q-item-label>
    </q-item-section>
  </q-item>

  <q-dialog v-model="logout" persistent full-width>
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">{{ t('mine.logOutMessage') }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat :label="t('common.ok')" color="primary" @click="handleLogOut" />
        <q-btn flat :label="t('common.cancel')" color="grey" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>

</style>