<script setup lang="ts">

import { object } from 'vue-types';
import { Spu } from '@/api/finance/trading/product/spu';
import { processProductIncome } from '@/utils/product';
import { processPriceUnit } from '@/utils/price';

defineOptions({
  name: 'ProductItem'
});

const props = defineProps({
  item: object<Spu>().isRequired
});

const { t } = useI18n();

const spu = ref<Spu>(props.item);

const { push } = useRouter();

processProductIncome(spu.value);

</script>

<template>
  <q-card @click="push({name: 'ProductDetail', params: { productId: spu.id}})"
          class="col-5 row q-mb-md items-center radius-sm bg-rey-2 shadow-2 shadow-up-1 text-center"
  >
    <!--名称和期限-->
    <q-card-section class="col-12 row q-px-sm q-pb-none column">
      <div v-text="spu.name" class="col text-subtitle2 text-weight-bold ellipsis" />
      <div class="col text-caption text-weight-medium ellipsis">
        {{ t('product.serving') }}
        {{ spu.totalPeriod }}
        {{ spu.totalPeriod > 1 ? t('product.days') : t('product.day') }}
      </div>
    </q-card-section>
    <!--图片-->
    <q-card-section class="col-12 row q-px-sm q-py-xs column">
      <q-img :src="spu.picUrl" class="full-width" loading="lazy"
             ratio="16/9" fit="contain" height="5rem"
      >
        <template #loading>
          <div class="text-primary">
            <q-spinner-ios />
<!--            <div class="q-mt-md">Loading...</div>-->
          </div>
        </template>
      </q-img>
    </q-card-section>
    <!--Daily income 和 Total income-->
    <q-card-section class="col-12 q-pa-sm text-center text-subtitle2">
      <div v-text="t('product.dailyIncome')" class="ellipsis" />

      <div class="text-primary text-weight-bold ellipsis">
        {{ processPriceUnit(spu.dailyIncome) }}
        <span class="text-grey-5">|</span>
        {{ spu.dailyReturnRate }}%
      </div>

      <div v-text="t('product.totalIncome')" class="ellipsis" />

      <div class="text-primary text-weight-bold ellipsis">
        {{ processPriceUnit(spu.totalIncome) }}
        <span class="text-grey-5">|</span>
        {{ spu.totalReturnRate }}%
      </div>
    </q-card-section>

    <q-separator />

    <q-card-actions class="column bg-primary full-width">
      <div v-text="`${t('product.price')} ${processPriceUnit(spu.price)}`"
           class="col text-subtitle2 text-white text-weight-bold ellipsis" />
    </q-card-actions>
  </q-card>
</template>

<style scoped>

</style>