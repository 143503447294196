import { defineStore } from 'pinia';
import { pinia } from '@/stores';
import { ChannelVO, getChannelList } from '@/api/finance/trading/pay/channel';

export const useChannel = defineStore('app-channel', {
  state: () => ({
    isSetChannel: false,
    channelList: [] as ChannelVO[]
  }),

  getters: {
    getChannelList(): ChannelVO[] {
      return this.channelList;
    },
    hasIndian(): boolean {
      return this.channelList.some(item => item.region === 'IN');
    }
  },

  actions: {
    reset(): void {
      this.isSetChannel = false;
    },
    async updateChannelList(): Promise<void> {
      this.channelList = await getChannelList();
      this.isSetChannel = true;
    }
  }
});

export const useChannelStore = () => {
  return useChannel(pinia);
};