<script setup lang="ts">

defineOptions({
  name: 'FAQItem'
})

const { push } = useRouter()
const { t } = useI18n()

</script>

<template>
  <q-item clickable v-ripple @click="push({name: 'Share'})">
    <q-item-section top avatar>
      <q-avatar rounded>
        <q-icon name="app:mine_referralLink" size="md"/>
      </q-avatar>
    </q-item-section>
    <q-item-section>
      <q-item-label class="text-subtitle1 text-weight-bolder">
        {{ t('mine.referralLink') }}
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<style scoped>

</style>