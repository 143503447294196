<script setup lang="ts">
import { getSpuDetail, Spu } from '@/api/finance/trading/product/spu';
import { processProductIncome } from '@/utils/product';
import { QLoading } from '@/utils/QLoading';
import { processPriceUnit } from '@/utils/price';

defineOptions({
  name: 'ProductDetail'
});

const props = defineProps({
  productId: {
    type: String,
    required: true
  }
});

QLoading.show();

const { t } = useI18n();
const { push, replace } = useRouter();

const { productId } = props;
const productDetail = ref<Spu>({} as Spu);

const getProductDetail = async () => {
  try {
    productDetail.value = await getSpuDetail(productId as unknown as number);
    processProductIncome(productDetail.value);
  } catch (e) {
    await replace({ name: 'Product' });
    return;
  } finally {
    QLoading.hide();
  }
};

getProductDetail();

const handleBuy = () => {
  push({ name: 'Payment', params: { productId } });
};

</script>

<template>
  <q-page class="bg-grey-2 row column">
    <!--图片-->
    <div class="column items-center q-py-md">
      <q-img :src="productDetail.picUrl" class="full-width"
             ratio="16/9" fit="contain" height="10rem"
      />
    </div>
    <!--底部-->
    <div class="col-grow column">
      <div class="row column q-px-lg">
        <!--描述 和 购买按钮-->
        <div class="row">
          <!--描述-->
          <div class="col row">
            <div v-text="productDetail.name" class="col-12 q-pr-md text-body1 text-wrap-keep text-weight-bold" />
            <div class="col-12 text-caption text-weight-medium ellipsis">
              {{ t('product.serving') }}
              {{ productDetail.totalPeriod }}
              {{ productDetail.totalPeriod > 1 ? t('product.days') : t('product.day') }}
            </div>
          </div>
          <!--购买按钮-->
          <div class="col column">
            <q-btn color="primary" no-caps class="full-height radius-sm" @click="handleBuy">
              <div class="row justify-center">
                <div v-text="t('product.buy')" class="col-12" />
                <div v-text="processPriceUnit(productDetail.price)"
                     class="text-subtitle1 text-weight-bold" />
              </div>
            </q-btn>
          </div>
        </div>
        <!--Daily income 和 Total income-->
        <div class="row q-pt-sm text-subtitle2">
          <div class="col-12 row">
            <div class="col-5" v-text="t('product.dailyIncome')" />
            <div class="col-7 text-right text-primary text-weight-bold ellipsis">
              {{ processPriceUnit(productDetail.dailyIncome) }}
              <span class="text-grey-5">|</span>
              {{ productDetail.dailyReturnRate }}%
            </div>
          </div>

          <div class="col-12 row">
            <div class="col-5" v-text="t('product.totalIncome')" />
            <div class="col-7 text-right text-primary text-weight-bold ellipsis">
              {{ processPriceUnit(productDetail.totalIncome) }}
              <span class="text-grey-5">|</span>
              {{ productDetail.totalReturnRate }}%
            </div>
          </div>
        </div>
      </div>
      <q-separator color="gray-5" spaced />
      <!--描述-->
      <div class="col-grow q-px-md text-wrap">
        <!--        <p class="text-subtitle2 text-weight-bold no-margin">{{ productDetail.introduction }}</p>-->
        <!--        <q-separator color="gray-5" spaced  size="lg" />-->
        <div v-html="productDetail.description" />
      </div>
    </div>
  </q-page>
</template>

<style scoped>

</style>