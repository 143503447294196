<script setup lang="ts">

import { fenToYuan, processPriceUnit } from '@/utils/price';
import { getPromoteIncomeSummary } from '@/api/finance/trading/pay/wallet/transaction';

defineOptions({
  name: 'LevelProfitCard'
});

const { t } = useI18n();
const { push } = useRouter();

const props = defineProps({
  level: {
    type: Number,
    required: true
  },
  effectiveCount: {
    type: Number,
    required: true
  },
  promoteCount: {
    type: Number,
    required: true
  },
  rate: {
    type: Number,
    required: true
  }
});

type IncomeSummary = {
  totalIncome: number;
  todayIncome: number;
}

const totalIncome = ref(0);
const todayIncome = ref(0);

const getProfitByLevel = async (level: number) => {
  const res: IncomeSummary = await getPromoteIncomeSummary(level);
  totalIncome.value = fenToYuan(res.totalIncome);
  todayIncome.value = fenToYuan(res.todayIncome);
};

// 跳转到CommissionDetail
const toCommissionDetail = () => {
  push({
    name: 'CommissionDetail',
    params: {
      level: props.level
    }
  });
}

onActivated(() => {
  getProfitByLevel(props.level);
})

</script>

<template>
  <q-card class="radius-sm no-shadow">
    <q-card-section class="q-pa-sm row items-center">
      <div class="col">
        <div class="text-body2 text-weight-bold">
          {{ t('myTeam.level') }} {{level}}
        </div>
        <div class="text-caption text-grey-8 text-weight-medium" style="line-height: 1em">
          {{ t('myTeam.numbers') }} <span class="text-primary">{{ effectiveCount }}</span> / {{ promoteCount }}
        </div>
      </div>
      <div class="col text-h5 text-right text-weight-bolder">
        {{rate}}%
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section class="row q-pa-none text-caption text-weight-bold">
      <div class="col q-py-sm column items-center">
        <div class="text-body2 text-weight-bold text-primary">
          {{ processPriceUnit(totalIncome) }}
        </div>
        <div>{{ t('myTeam.totalCommission') }}</div>
      </div>
      <q-separator vertical />
      <div class="col q-py-sm column items-center">
        <div class="text-body2 text-weight-bold text-primary">
          {{ processPriceUnit(todayIncome) }}
        </div>
        <div>{{ t('myTeam.todayCommission') }}</div>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section class="q-py-md row justify-center items-center">
      <q-btn no-caps flat rounded
             class="bg-primary text-white q-px-xl"
             :label="'Details'"
             @click="toCommissionDetail"
      />
    </q-card-section>
  </q-card>
</template>

<style scoped>

</style>