<script setup lang="ts">

import { useUserStore } from '@/stores/user';

defineOptions({
  name: 'Home'
});

import { MyBalance } from '@/views/Common';
import { useCounterStore } from '@/stores/example-store';
import { CompanyIntro } from '@/views/About/src/components';

const counterStore = useCounterStore();
const { doubleCount  } = toRefs(counterStore);
const { increment } = counterStore

const userStore = useUserStore();
const { incrementBalance, decrementBalance } = userStore;


</script>

<template>
  <q-page class="bg-grey-2">
    <MyBalance />
    <!--描述-->
    <CompanyIntro />
<!--    <q-card class="q-pa-md row justify-center">-->
<!--      <q-card-section class="col-10 column items-center">-->
<!--        <q-icon name="home" size="5rem" />-->
<!--        <q-input v-model="userStore.getId" clearable/>-->
<!--        <q-input v-model="userStore.balance" clearable/>-->
<!--      </q-card-section>-->
<!--      <q-card-section>-->
<!--        <q-btn color="primary" label="测试按钮+" @click="incrementBalance" />-->
<!--        <q-btn color="primary" label="测试按钮-" @click="decrementBalance" />-->
<!--      </q-card-section>-->
<!--    </q-card>-->
  </q-page>
</template>

<style scoped>

</style>