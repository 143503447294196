<script setup lang="ts">

import ListTail from '@/components/InfiniteLoad/src/Tail/ListTail.vue';

defineOptions({
  name: 'InfiniteLoad'
});

const props = defineProps({
  dataList: {
    type: Array,
    required: true
  },
  loadFunc: {
    type: Function,
    required: true
  },
  pageSize: {
    type: Number,
    default: 10
  }
});

const { dataList, pageSize, loadFunc } = props;
const items = ref(dataList);

const showNoMore = ref(false)

const scrollRef = ref();
const handleLoad = async (index, done) => {
  showNoMore.value = false
  const res: {list: [], total: number} = await loadFunc(index, pageSize);
  const totalReq = index * pageSize;
  if (!res || totalReq >= res.total  || res.list.length === 0) {
    scrollRef.value.stop();
    showNoMore.value = true
  }
  // console.log('loadFunc', res);
  items.value.push(...res.list);
  done();
};

// onMounted(() => {
//   handleLoad()
// })

// region 保存/加载滚动条位置
const scrollAreaRef = ref();
const scrollPosition = ref(0);
const saveScrollPosition = () => {
  scrollPosition.value = scrollAreaRef.value.getScrollPosition().top;
};
const scrollTo = (position: number) => {
  scrollAreaRef.value.setScrollPosition(
    'vertical', position, 0
  );
};
onActivated(() => {
  scrollTo(scrollPosition.value);
});

onDeactivated(() => {
  saveScrollPosition();
});
// endregion 保存/加载滚动条位置

// 重新加载
const reload = () => {
  items.value = [];
  scrollRef.value.reset();
  scrollRef.value.resume();
  scrollRef.value.trigger();
};
defineExpose({reload})
</script>

<template>
  <q-scroll-area :thumb-style="{opacity: '0'}"
                 ref="scrollAreaRef"
                 :style="{ height: 'calc(100% - 1rpx)'}"
                 style="width: 100%">
    <q-infinite-scroll class="full-width" ref="scrollRef"
                       @load="handleLoad" :offset="250">
      <!--      <q-item v-for="(item, index) in orderList"-->
      <!--              :key="index" class="q-px-none"-->
      <!--      >-->
      <slot :items="items"></slot>
      <!--      </q-item>-->
      <template v-slot:loading>
        <div class="text-center q-my-md">
          <q-spinner-dots color="primary" size="40px" />
        </div>
      </template>
      <ListTail :showTail="showNoMore"></ListTail>
    </q-infinite-scroll>
  </q-scroll-area>
</template>

<style scoped>

</style>