export default {
  failed: '操作失败',
  success: '操作成功',
  common: {
    inputText: '请输入',
    selectText: '请选择',
    startTimeText: '开始时间',
    endTimeText: '结束时间',
    login: '登录',
    required: '该项为必填项',
    loginOut: '退出系统',
    document: '项目文档',
    profile: '个人中心',
    reminder: '温馨提示',
    loginOutMessage: '是否退出本系统？',
    back: '返回',
    ok: '确定',
    save: '保存',
    cancel: '取消',
    close: '关闭',
    reload: '重新加载',
    success: '成功',
    closeTab: '关闭标签页',
    closeTheLeftTab: '关闭左侧标签页',
    closeTheRightTab: '关闭右侧标签页',
    closeOther: '关闭其他标签页',
    closeAll: '关闭全部标签页',
    prevLabel: '上一步',
    nextLabel: '下一步',
    skipLabel: '跳过',
    doneLabel: '结束',
    menu: '菜单',
    menuDes: '以路由的结构渲染的菜单栏',
    collapse: '展开缩收',
    collapseDes: '展开和缩放菜单栏',
    tagsView: '标签页',
    tagsViewDes: '用于记录路由历史记录',
    tool: '工具',
    toolDes: '用于设置定制系统',
    query: '查询',
    reset: '重置',
    shrink: '收起',
    expand: '展开',
    confirmTitle: '系统提示',
    exportMessage: '是否确认导出数据项？',
    importMessage: '是否确认导入数据项？',
    createSuccess: '新增成功',
    updateSuccess: '修改成功',
    delMessage: '是否删除所选中数据？',
    delDataMessage: '是否删除数据？',
    delNoData: '请选择需要删除的数据',
    delSuccess: '删除成功',
    index: '序号',
    status: '状态',
    createTime: '创建时间',
    updateTime: '更新时间',
    copy: '复制',
    copySuccess: '复制成功',
    copyError: '复制失败',
    processing: '处理中...',
    submit: '提交'
  },
  error: {
    noPermission: `抱歉，您无权访问此页面。`,
    pageError: '抱歉，您访问的页面不存在。',
    networkError: '抱歉，服务器报告错误。',
    returnToHome: '返回首页'
  },
  login: {
    welcome: 'Welcome',
    message: '开箱即用的中后台管理系统',
    tenantName: '租户名称',
    username: '用户名',
    password: '密 码',
    code: '验证码',
    login: '登录',
    toSignUp: '注册新账户? ',
    signUp: '注册',
    toLogin: '已有账户? ',
    reLogin: '重新登录',
    register: '注册',
    checkPassword: '确认密码',
    remember: '记住我',
    hasUser: '已有账号？去登录',
    forgetPassword: '忘记密码?',
    usernamePlaceholder: '请输入用户名',
    phoneNumberPlaceholder: '请输入手机号',
    passwordPlaceholder: '请输入密码',
    codePlaceholder: '请输入验证码',
    phoneNumber: '手 机',
    backLogin: '返回',
    loginBtn: '登 录',
    registerBtn: '注 册',
    // 校验相关
    rules: {
      noPhoneError: '请填写手机',
      noPasswordError: '请输入密码',
      phoneNumberError: '请输入正确的手机号码',
      passwordError: '密码长度必须在 6 到 20 个字符之间',
    },
  },
  captcha: {
    verification: '请完成安全验证',
    slide: '向右滑动完成验证',
    point: '请依次点击',
    success: '验证成功',
    fail: '验证失败'
  },
  router: {
    login: '登录',
    home: '首页',
    product: '产品',
    wallet: '钱包',
    order: '订单',
    mine: '我的',
    productDetail: '产品详情',
    paymentRecords: '支付记录',
    myTeam: '我的团队',
    payment: '支付',
    paymentRecord: '付款记录',
    aboutUs: '关于',
    faq: 'FAQ',
    share: '分享',
  },
  form: {
    input: '输入框',
    inputNumber: '数字输入框',
    default: '默认',
    icon: '图标',
    mixed: '复合型',
    textarea: '多行文本',
    slot: '插槽',
    position: '位置',
    autocomplete: '自动补全',
    select: '选择器',
    selectGroup: '选项分组',
    selectV2: '虚拟列表选择器',
    cascader: '级联选择器',
    switch: '开关',
    rate: '评分',
    colorPicker: '颜色选择器',
    transfer: '穿梭框',
    render: '渲染器',
    radio: '单选框',
    button: '按钮',
    checkbox: '多选框',
    slider: '滑块',
    datePicker: '日期选择器',
    shortcuts: '快捷选项',
    today: '今天',
    yesterday: '昨天',
    aWeekAgo: '一周前',
    week: '周',
    year: '年',
    month: '月',
    dates: '日期',
    daterange: '日期范围',
    monthrange: '月份范围',
    dateTimePicker: '日期时间选择器',
    dateTimerange: '日期时间范围',
    timePicker: '时间选择器',
    timeSelect: '时间选择',
    inputPassword: '密码输入框',
    passwordStrength: '密码强度',
    operate: '操作',
    change: '更改',
    restore: '还原',
    disabled: '禁用',
    disablement: '解除禁用',
    delete: '删除',
    add: '添加',
    setValue: '设置值',
    resetValue: '重置值',
    set: '设置',
    subitem: '子项',
    formValidation: '表单验证',
    verifyReset: '验证重置',
    remark: '备注'
  },
  watermark: {
    watermark: '水印'
  },
  dialog: {
    dialog: '弹窗',
    open: '打开',
    close: '关闭'
  },
  sys: {
    api: {
      operationFailed: '操作失败',
      errorTip: '错误提示',
      errorMessage: '操作失败,系统异常!',
      timeoutMessage: '登录超时,请重新登录!',
      apiTimeoutMessage: '接口请求超时,请刷新页面重试!',
      apiRequestFailed: '请求出错，请稍候重试',
      networkException: '网络异常',
      networkExceptionMsg: '网络异常，请检查您的网络连接是否正常!',
      errMsg401: '用户没有权限（令牌、用户名、密码错误）!',
      errMsg403: '用户得到授权，但是访问是被禁止的。!',
      errMsg404: '网络请求错误,未找到该资源!',
      errMsg405: '网络请求错误,请求方法未允许!',
      errMsg408: '网络请求超时!',
      errMsg500: '服务器错误,请联系管理员!',
      errMsg501: '网络未实现!',
      errMsg502: '网络错误!',
      errMsg503: '服务不可用，服务器暂时过载或维护!',
      errMsg504: '网络超时!',
      errMsg505: 'http版本不支持该请求!',
      errMsg901: '演示模式，无法进行写操作!'
    },
    app: {
      logoutTip: '温馨提醒',
      logoutMessage: '是否确认退出系统?',
      menuLoading: '菜单加载中...'
    },
    exception: {
      backLogin: '返回登录',
      backHome: '返回首页',
      subTitle403: '抱歉，您无权访问此页面。',
      subTitle404: '抱歉，您访问的页面不存在。',
      subTitle500: '抱歉，服务器报告错误。',
      noDataTitle: '当前页无数据',
      networkErrorTitle: '网络错误',
      networkErrorSubTitle: '抱歉，您的网络连接已断开，请检查您的网络！'
      }
  },
  profile: {
    user: {
      title: '个人信息',
      username: '用户名称',
      nickname: '用户昵称',
      mobile: '手机号码',
      email: '用户邮箱',
      dept: '所属部门',
      posts: '所属岗位',
      roles: '所属角色',
      sex: '性别',
      man: '男',
      woman: '女',
      createTime: '创建日期'
    },
    info: {
      title: '基本信息',
      basicInfo: '基本资料',
      resetPwd: '修改密码',
      userSocial: '社交信息'
    },
    rules: {
      nickname: '请输入用户昵称',
      mail: '请输入邮箱地址',
      truemail: '请输入正确的邮箱地址',
      phone: '请输入手机号码',
      truephone: '请输入正确的手机号码'
    },
    password: {
      oldPassword: '旧密码',
      newPassword: '新密码',
      confirmPassword: '确认密码',
      oldPwdMsg: '请输入旧密码',
      newPwdMsg: '请输入新密码',
      cfPwdMsg: '请输入确认密码',
      pwdRules: '长度在 6 到 20 个字符',
      diffPwd: '两次输入密码不一致'
    }
  },
  user: {
    balance: '余 额',
    totalIncome: '总收益',
    todayIncome: '今日收益',
    paymentRecords: '付款记录'
  },
  product: {
    dailyIncome: '日收益',
    totalIncome: '总收益',
    buy: '立即购买',
    placeAnOrder: '立即购买',
    price: '售价',
    serving: '为期',
    day: '天',
    days: '天',
    viewDetail: '查看详情 >',
    investmentPlans: '投资计划',
    investmentPlansInfo: '选择一个计划，开始您的赚钱之旅，点击查看更多产品信息。',
  },
  order: {
    dailyIncome: '日收益',
    totalIncome: '总收益',
    alreadyObtained: '已获得',
    servingTime: '为期',
    day: '天',
    days: '天',
    dailyReturnTime: '每日返还时间',
    price: '售价',
    orderNotPaid: '未支付',
  },
  bank: {
    card: '银行卡',
    cardInfo: '银行卡信息',
    holderName: '持卡人姓名',
    bankName: '银行名称',
    cardNo: '银行卡号',
    ifsc: 'IFSC',
    holderNamePlaceholder: '请输入持卡人姓名',
    cardNoPlaceholder: '请输入银行卡号',
    ifscPlaceholder: '请输入IFSC',
    rules: {
      noHolderNameError: '请输入持卡人姓名',
      noBankIdError: '请选择银行',
      noCardNoError: '请输入银行卡号',
      noIfscError: '请输入IFSC',
      ifscError: '请输入正确的IFSC',
    }
  },
  withdraw: {
    withdraw: '提现',
    withdrawRecord: '提现记录',
    withdrawAmount: '提现金额',
    withdrawAmountPlaceholder: '请输入提现金额',
    bankNotOk: '银行卡信息未完善，无法提现',
    // 校验相关
    rules: {
      noWithdrawAmountError: '提款金额 必填',
      withdrawAmountError: '提款金额必须大于最低提款金额',
    },
    aboutWithdraw: '关于提现',
    processTime: '受理时间',
    minimumAmount: '最小金额',
    arrivalTime: '到账时间',
    fees: '手续费',
    withdrawBtn: '提现',
    withdrawNo: '编号',
    price: '金额',
    withdrawStatus: {
      auditing: '处理中',
      approved: '处理中',
      success: '提现成功',
      'audit failed': '提现失败',
      'withdraw failed': '提现失败',
    }
  },
  payment: {
    productName: '名称',
    productPrice: '价格',
    chooseMethod: '选择支付方式',
    pay: '支付',
    paySuccess: '支付成功',
    payFailed: '支付失败',
  },
  myTeam: {
    level: '等级',
    numbers: '数量',
    totalCommission: '佣金总额',
    todayCommission: '今日佣金',
    commission: {
      invite: '邀请',
      commissionList: '佣金列表',
      totalRecharge: '充值总额',
      myCommission: '我的佣金',
    },
  },
  share: {
    link: '链接',
    promotionMaterial: '推广材料',
    promotionContent: '新的合法赚钱平台已经推出。在这里，您可以投资一个赚取巨额每日收入的计划，也可以邀请您的朋友赚取佣金。3个级别的推荐佣金为30%、4%和1%。注册以获得奖金NGN 200和免费NGN 1000有价值的产品。通过以下链接注册'
  },
  paymentRecord: {
    paymentRecord: '支付记录',
    remark: '如果您在付款后没有响应，请在此上传您的交易收据截图，其中应包含付款时间，并记得提交给我们进行审核，您的订单将在 24 小时内确认',
    payStatus: {
      paid: '已支付',
      unPaid: '未支付',
      pending: '处理中',
    },
    orderNo: '支付单编号',
    productName: '产品名称',
    price: '价格',
    upload: '上传',
    uploadBtn: '上传凭证',
    congrats: '购买成功',
    uploadProof: '上传凭证',
    uploadImg: '请选择一张图片',
    uploadProofDesc: '凭证说明',
    uploadProofDescPlaceHolder: '请输入凭证说明'
  },
  mine: {
    myTeam: '我的团队',
    faq: 'FAQ',
    official: '官方频道',
    paymentRecord: '付款记录',
    referralLink: '推广链接',
    aboutUs: '关于',
    logOut: '注销',
    logOutMessage: '确定要注销吗？',
  }
};