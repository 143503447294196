<script setup lang="ts">

defineOptions({
  name: 'Me'
});

import { MyBalance } from '@/views/Common';
import { MyTeamItem, FAQItem, OfficialItem, PaymentItem, ReferralLinkItem, LogOutItem } from './components';

const { push } = useRouter();
const { t } = useI18n();

</script>

<template>
  <q-page class="bg-grey-2 column">
    <MyBalance />

    <q-list class="text-primary">
      <!--MyTeam-->
      <MyTeamItem />
      <q-separator inset="item" />
      <FAQItem />
      <q-separator inset="item" />
      <OfficialItem />
      <q-separator inset="item" />
      <PaymentItem />
      <q-separator inset="item" />
      <ReferralLinkItem />
      <q-separator inset="item" />
      <LogOutItem />
    </q-list>
  </q-page>
</template>

<style scoped>

</style>