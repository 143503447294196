import { QNotifyCreateOptions } from 'quasar';

import { Notify } from 'quasar';

const notify = (option: QNotifyCreateOptions) => {
  const notify = Notify.create({
    position: 'top',
    timeout: 500,
    ...option
  });
  return notify;

};

export const QNotify = {
  success: (message: string) => {
    return notify({ type: 'positive', message });
  },
  error: (message: string) => {
    return notify({ type: 'negative', message });
  },
  warning: (message: string) => {
    return notify({ type: 'warning', message });
  },
  info: (message: string) => {
    return notify({ type: 'info', message });
  },
  custom: (option: QNotifyCreateOptions) => {
    return notify(option);
  }
  // }
};