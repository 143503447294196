import { useConfigStore } from '@/stores/manageConfig';

const { getCurrencySymbol } = toRefs(useConfigStore());

export const processPriceUnit = (price: number) => {
  return getCurrencySymbol.value + price;
};

/**
 * 分转元
 */
export function fenToYuan(amount: number): number {
  // 将分转换为元，即除以100
  const yuan = amount / 100;

  // 使用toFixed方法保留两位小数，并去除末尾的0
  const formattedYuan = yuan.toFixed(2);

  return parseFloat(formattedYuan); // 返回转换后的金额，确保是数字类型
}

/**
 * 元转分
 */
export function yuanToFen(amount: string | number): number {
  // 将金额转换为数字类型
  const numAmount = typeof amount === 'string' ? parseFloat(amount) : amount

  // 将金额乘以100，得到分, 并去除小数位
  return Math.round(numAmount * 100);
}