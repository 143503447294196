<script setup lang="ts">

import { useUserStore } from '@/stores/user';
import { processPriceUnit } from '@/utils/price';

const { t } = useI18n();

defineOptions({
  name: 'MyBalance'
});

const {currentRoute} = useRouter()

// 从userStore获取id和余额
const { getMobile, getBalance } = toRefs(useUserStore());
const { updateBalance } = useUserStore();

// onMounted(() => {
//   updateBalance();
// })

// watch(
//   () => currentRoute.value,
//   () => {
//     return debounce(() => updateBalance(), 100);
//   }
// )

onActivated(() => {
  updateBalance()
})


</script>

<template>
  <div class="text-center q-py-md">
    <div class="col-12">{{ `ID ${getMobile}` }}</div>
    <div class="col-12 text-h5 text-weight-bold">{{ processPriceUnit(getBalance) }}</div>
    <div class="col-12">{{ t('user.balance') }}</div>
  </div>
</template>

<style scoped>

</style>