<script setup lang="ts">

defineOptions({
  name: 'WithdrawList'
});

import { getWithdrawPage, WithdrawVO } from '@/api/finance/trading/withdraw';
import WithdrawItem from './WithdrawItem.vue';
import { EmitType, useEmitt } from '@/hooks/web/useEmitt';

const { t } = useI18n();

const withdrawList = ref<WithdrawVO[]>([]);

const getList = async (pageNo: number, pageSize: number) => {
  const res = await getWithdrawPage({ pageNo, pageSize });
  return res;
};

const listRef = ref();

const reload = () => {
  listRef.value.reload();
};

// onBeforeUnmount时会自动取消
useEmitt({
  name: EmitType.withdrawn,
  callback: reload
});

// onBeforeUnmount时需要手动取消监听事件
// emitter.on(EmitType.withdrawn,  reload)

</script>

<template>
  <div v-text="t('withdraw.withdrawRecord')" class="q-pl-sm text-subtitle2 text-weight-bold" />
  <q-separator />
  <q-list class="col-grow q-px-sm row withdraw-list">
    <InfiniteLoad ref="listRef" :data-list="withdrawList" :load-func="getList">
      <template #default="{ items }">
        <q-item v-for="(item, index) in items"
                :key="index" class="q-px-none"
        >
          <WithdrawItem :item="item as WithdrawVO" />
        </q-item>
      </template>
    </InfiniteLoad>
  </q-list>
</template>

<style lang="scss" scoped>

</style>