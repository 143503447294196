<script setup lang="ts">
defineOptions({
  name: 'ProductList'
});

import { ProductItem } from './index';
import { getSpuList, Spu } from '@/api/finance/trading/product/spu';

const productList = ref<Spu[]>([]);

const getProductList = async () => {
  productList.value = await getSpuList();
};

getProductList();

// onActivated(() => {
//   getProductList()
// })

const refresh = async (done) => {
  await getProductList();
  done();
};


</script>

<template>
  <q-card class="scroll q-px-xs q-pt-lg col-grow row radius-top-lg no-shadow product-list">
    <q-scroll-area class="q-pt-sm no-border-radius"
                   :thumb-style="{opacity: '0'}"
                   :style="{ height: 'calc(100% - 1rpx)'}"
                   style="width: 100%">
      <!--      <q-pull-to-refresh class="row full-width full-height" @refresh="refresh">-->
      <div class="q-pa-sm row justify-around q-gutter-sm">
        <ProductItem v-for="product in productList"
                     :key="product.id"
                     :item="product"
        />
        <!--        数量是奇数时需要占位-->
        <div v-if="productList.length % 2 === 1" class="col-5" />
      </div>

      <!--      </q-pull-to-refresh>-->
    </q-scroll-area>
  </q-card>
</template>

<style lang="scss" scoped>

</style>