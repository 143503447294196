<script setup lang="ts">

import { RecordDesc, RecordList } from '@/views/PaymentRecords/src/components';

defineOptions({
  name: 'PaymentRecords'
});


const msg = ref('');

onMounted(() => {
  msg.value = 'Welcome to Me';
});
</script>

<template>
  <q-page class="bg-grey-2 q-pa-md column">
<!--说明-->
    <RecordDesc />
<!--记录列表-->
    <RecordList />
  </q-page>
</template>

<style scoped>

</style>