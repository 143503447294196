<script setup lang="ts">

import { useConfigStore } from '@/stores/manageConfig';

defineOptions({
  name: 'FAQItem'
});

const { t } = useI18n();

const { toOfficialChannel } = useConfigStore();

</script>

<template>
  <q-item clickable v-ripple @click="toOfficialChannel">
    <q-item-section top avatar>
      <q-avatar rounded>
        <q-icon name="app:mine_officialChannel" size="md" />
      </q-avatar>
    </q-item-section>
    <q-item-section>
      <q-item-label class="text-subtitle1 text-weight-bolder">
        {{ t('mine.official') }}
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<style scoped>

</style>